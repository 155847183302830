import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ SvgComponent, fillColor, link }) => {
	return (
		<div className="d-inline-flex" data-testid="dealer-badge">
			{link ? (
				<a className="d-flex" href={link}>
					<SvgComponent fillColor={fillColor} />
				</a>
			) : (
				<SvgComponent fillColor={fillColor} />
			)}
		</div>
	);
};

Badge.propTypes = {
	SvgComponent: PropTypes.func.isRequired,
	fillColor: PropTypes.string.isRequired,
	link: PropTypes.string
};

export default Badge;
