import { SET_WIDGET_INFO } from '../actions/types';

export default function widgetInfo(
	state = { isComposer: false },
	{ type, payload }
) {
	if (payload) {
		switch (type) {
			case SET_WIDGET_INFO:
				return payload;

			// no default
		}
	}

	return state;
}
