import '../global-sass/main.scss';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePrefs, useSitemap, useLabels } from 'wsm-common-data';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { setClassNames } from 'ddc-classnames-js';
import logger from 'ws-scripts/modules/logger';
import Badge from '../components/Badge';
import Placeholder from '../components/Placeholder';

const Widget = () => {
	const labels = useLabels();
	const { badgeData } = useSelector((state) => state);
	const { isComposer } = useSelector((state) => state.widgetInfo);
	const hasBadgesEnabled = badgeData.length > 0;
	const [badgesToDisplay, setBadgesToDisplay] = useState(
		hasBadgesEnabled
			? badgeData.map(({ badgeDirectory, badgeComponent }) => {
					return (
						<Placeholder
							key={`${badgeDirectory}-${badgeComponent}`}
						/>
					);
			  })
			: []
	);
	const { fillColor, badgeGapClasses } = usePrefs();
	const sitemap = useSitemap();

	useEffect(() => {
		const importBadges = async () =>
			Promise.all(
				badgeData.map(
					async ({ badgeDirectory, badgeComponent, linkAlias }) => {
						const { default: SvgComponent } = await import(
							`../components/badges/${badgeDirectory}/${badgeComponent}`
						);
						return (
							<Badge
								key={`${badgeDirectory}-${badgeComponent}`}
								SvgComponent={SvgComponent}
								fillColor={fillColor}
								link={sitemap.get(linkAlias)}
							/>
						);
					}
				)
			);

		if (hasBadgesEnabled) {
			importBadges()
				.then(setBadgesToDisplay)
				.catch((error) => {
					logger.error(error);
					setNewRelicCustomAttribute(
						'wsDealershipBadgesErrorMessageClient-importBadges',
						error.message
					);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (hasBadgesEnabled) {
		return (
			<div
				data-testid="dealer-badges"
				className={setClassNames([
					'd-flex',
					'flex-wrap',
					...badgeGapClasses.split(' ')
				])}
			>
				{badgesToDisplay}
			</div>
		);
	}

	return isComposer ? (
		<div className="alert alert-info m-0 p-2 ddc-font-size-small d-inline-block">
			<i aria-hidden="true" className="ddc-icon ddc-icon-alert" />
			<div className="alert-content">
				{labels.get('EDIT_SITE_PROPERTIES')}
			</div>
		</div>
	) : null;
};

export default Widget;
