import React from 'react';
import PropTypes from 'prop-types';

const Placeholder = ({ width = '64', height = '26' }) => {
	return (
		<svg
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			role="presentation"
		/>
	);
};

Placeholder.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string
};

export default Placeholder;
