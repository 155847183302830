import { SET_BADGE_DATA } from '../actions/types';

export default function badgeData(state = [], { type, payload }) {
	if (payload) {
		switch (type) {
			case SET_BADGE_DATA:
				return payload;

			// no default
		}
	}

	return state;
}
